import axios from 'axios';
import Constants from '@/constants';
import Video from '@/models/Video';
import { plainToClass } from 'class-transformer';

export default class VideosApi {
  public static async uploadVideos(formData: FormData, progressCallback: (progressEvent: ProgressEvent) => void): Promise<void> {
    const config = { onUploadProgress: progressCallback };
    return await axios.post(`${Constants.api}/videos/upload`, formData, config);
  }

  public static async getVideo(id: number) {
    return await axios.get(`${Constants.api}/videos/${id}`);
  }

  public static async getThumbnail(id: number) {
    return await axios.get(`${Constants.api}/videos/${id}/thumbnail`);
  }

  public static async getAllVideos(): Promise<Video[]> {
    const response =  await axios.get(`${Constants.api}/videos/all`);
    return plainToClass(Video, response.data as unknown[]);
  }
}














































import VMessage from '@/components/VMessage.vue';
import VButton from '@/components/VButton.vue';
import VideosApi from '@/api/Videos';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: { VMessage, VButton }
})
export default class UploadVideos extends Vue {
  public dropZoneActive = false;
  public files: File[] = [];
  public invalidFiles: string[] = [];
  public progress = 0;
  public loading = false;

  public filesDropped($event: DragEvent) {
    this.dropZoneActive = false;
    const newFiles = Array.from($event.dataTransfer?.files ?? []);
    this.addFiles(newFiles);
  }

  public onFileChange($event: Event) {
    const target: HTMLInputElement = $event.target as HTMLInputElement;
    const newFiles = Array.from(target.files ?? []);
    this.addFiles(newFiles);
  }

  public addFiles(newFiles: File[]) {
    this.invalidFiles = [];
    const maxFileSize = 100 * 1000000; // 100 MB
    const isValid = (file: File) => file.type.match('video/*') && file.size <= maxFileSize;

    const validFiles = newFiles.filter(isValid);
    const invalidFiles = newFiles.filter((f) => !isValid(f));

    if (invalidFiles.length > 0) {
      this.invalidFiles = invalidFiles.map((f) => f.name);
      setTimeout(() => this.invalidFiles = [], 10000);
    }

    this.files.push(...validFiles);
  }

  public upload() {
    this.loading = true;
    const formData = new FormData();
    this.files.forEach((f) => {
      formData.append('files', f, f.name);
    });

    this.progress = 0;
    const totalSize = this.files.reduce((acc,f) => acc += f.size, 0);
    const progressEvent = (progressEvent: ProgressEvent) => {
      this.progress = Math.round((progressEvent.loaded / totalSize) * 100)
    }
    VideosApi.uploadVideos(formData, progressEvent).then((response) => {
      console.log(formData);
    }).catch((error) => {
      this.$root.$emit('handleError', error);
    }).finally(() => {
      this.loading = false;
    });
  }
}

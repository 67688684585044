import { Type } from 'class-transformer';
import { Club } from './Club';

export default class Video {
  public id: number;
  @Type(() => Date) public date: Date;
  public name: string;
  public description: string;
  public fileName: string;
  public location: string;
  public technique: string;
  public club: Club;
}